<template>
  <div id="products">
    <Navigation />
    <v-main class="content mb-9" style="position: relative">
      <v-container fluid>
        <v-card style="border-radius: 2px" color="#383E45">
          <v-container fluid class="py-5 px-6">
            <HeaderPage title="สินค้าทั้งหมด" />

            <v-col cols="12" class="px-1">
              <v-row>
                <v-col cols="12" md="6">
                  <label for="">ชื่อสินค้า</label>
                  <v-text-field
                    v-model.trim="addProductNameInput"
                    class="mt-2"
                    label="เพิ่มสินค้า"
                    solo
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2">
                  <label for="">ราคาสินค้า</label>
                  <v-text-field
                    v-model.trim="addProductPriceInput"
                    class="mt-2"
                    label="ราคาสินค้า"
                    type="number"
                    solo
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2">
                  <label for="">สต๊อกสินค้า</label>
                  <v-text-field
                    v-model.trim="addProductStockInput"
                    class="mt-2"
                    label="จำนวนสต๊อกสินค้า"
                    type="number"
                    solo
                    dense
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="mt-0 mt-md-11 text-right text-md-left pt-0 pl-1 mb-md-0 mb-7"
                >
                  <v-btn
                    height="38px"
                    class="ml-2"
                    color="blue"
                    @click.prevent="addProduct"
                  >
                    <v-icon left>mdi-plus</v-icon>
                    เพิ่มสินค้า
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-divider color="grey" class="mb-6"></v-divider>

            <v-col cols="12" class="px-0 pb-1">
              <v-row>
                <v-col cols="12" lg="8" xl="6" class="d-flex pb-0">
                  <v-text-field
                    label="ค้นหาชื่อสินค้า"
                    solo
                    dense
                    hide-details
                    v-model="searchProductInput"
                  ></v-text-field>
                  <v-btn
                    height="38px"
                    class="ml-2"
                    outlined
                    color="white"
                    @click.prevent="searchProduct"
                  >
                    <v-icon left>mdi-magnify</v-icon>
                    ค้นหา
                  </v-btn>
                </v-col>
                <v-col cols="12" lg="4" xl="6" class="text-right">
                  <v-btn
                    @click.prevent="displayAllProducts"
                    height="38px"
                    color="blue"
                    outlined
                    >ทั้งหมด</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
            <div class="wrapper-table mb-1 mt-3">
              <table class="table-table">
                <tr class="table-head">
                  <th>สินค้าที่</th>
                  <th>ชื่อสินค้า</th>
                  <th>ราคาสินค้า</th>
                  <th>จำนวนสต๊อกสินค้า</th>
                  <th>จัดการสินค้า</th>
                </tr>
                <tr v-if="products.length === 0">
                  <td colspan="5">
                    <template>
                      <div
                        style="font-size: 20px"
                        class="d-flex flex-column align-center red--text my-4"
                      >
                        <v-img
                          class="mt-2 mb-4"
                          width="200"
                          src="@/assets/no-data.svg"
                          alt=""
                        >
                        </v-img>
                        ไม่พบข้อมูลรายการสินค้า
                      </div>
                    </template>
                  </td>
                </tr>
                <tr
                  class="table-body"
                  v-for="(item, index) in products"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.product_name }}</td>
                  <td>{{ item.price | currency2 }}</td>
                  <td>{{ item.stock | currency2 }}</td>
                  <td>
                    <v-btn
                      class="white--text"
                      small
                      color="warning"
                      @click.prevent="
                        openModalEdit(
                          item.id,
                          item.price,
                          item.product_name,
                          item.stock
                        )
                      "
                      >แก้ไข</v-btn
                    >

                    <v-btn
                      @click.prevent="deleteProduct(item.id)"
                      class="ml-2 white--text"
                      small
                      color="red"
                      >ลบ</v-btn
                    >
                  </td>
                </tr>
              </table>
            </div>
          </v-container>
        </v-card>
      </v-container>
      <div class="modal-edit-tracking-list" v-if="modal === true">
        <div class="modal-title mt-1 mb-4">แก้ไขข้อมูล</div>
        <v-divider color="white" class="mb-4"></v-divider>
        <v-form v-on:submit.prevent="updateProduct()">
          <v-col cols="12">
            <label for="">ชื่อสินค้า</label>
            <v-text-field
              v-model="edit.product_name"
              solo
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <label for="">ราคาสินค้า</label>
            <v-text-field
              v-model="edit.price"
              type="number"
              solo
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <label for="">สต๊อกสินค้า</label>
            <v-text-field
              v-model="edit.stock"
              type="number"
              solo
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" xl="12" class="px-0 text-right">
            <v-btn
              :loading="loadingEdit"
              large
              color="blue"
              @click.prevent="updateProduct()"
            >
              <v-icon left>mdi-checkbox-marked-circle-outline</v-icon>
              บันทึกการแก้ไข
            </v-btn>
            <v-btn
              class="ml-6"
              large
              outlined
              color="red"
              @click.prevent="closeModalEdit()"
              >ยกเลิก</v-btn
            >
          </v-col>
        </v-form>
      </div>
    </v-main>
    <FooterAdmin />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation';
import FooterAdmin from '@/components/FooterAdmin';
import HeaderPage from '@/components/HeaderPage';

import ProductService from '@/services/Product.service';

import Swal from 'sweetalert2';

export default {
  name: 'Products',
  components: {
    Navigation,
    FooterAdmin,
    HeaderPage,
  },
  data() {
    return {
      modal: false,
      addProductNameInput: '',
      addProductPriceInput: 0,
      addProductStockInput: 0,
      product_id: null,
      updateProductNameInput: '',
      products: [],
      searchProductInput: '',
      edit: {
        product_id: null,
        product_name: '',
        price: 0,
        stock: 0,
      },
      loadingEdit: false,
    };
  },
  created() {
    this.getProducts();
  },
  methods: {
    async getProducts() {
      try {
        const response = await ProductService.findAll();
        this.products = response;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    async addProduct() {
      if (this.addProductNameInput) {
        const body = {
          product_name: this.addProductNameInput,
          price: this.addProductPriceInput,
          stock: this.addProductStockInput,
        };
        try {
          const response = await ProductService.insert(body);
          this.getProducts();
          Swal.fire({
            position: 'bottom-end',
            toast: true,
            icon: 'success',
            title: 'เพิ่มสินค้าสำเร็จ',
            showConfirmButton: false,
            timer: 3000,
          });
          this.addProductNameInput = '';
          this.addProductPriceInput = 0;
          this.addProductStockInput = 0;
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: error.msg,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }
    },
    async searchProduct() {
      try {
        const response = await ProductService.findOne(this.searchProductInput);
        this.products = response;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    displayAllProducts() {
      this.searchProductInput = '';
      this.getProducts();
    },
    openModalEdit(product_id, price, product_name, stock) {
      this.modal = true;
      this.edit.product_id = product_id;
      this.edit.product_name = product_name;
      this.edit.price = price;
      this.edit.stock = stock;
    },
    closeModalEdit() {
      this.modal = false;
      this.edit.product_id = null;
      this.edit.product_name = '';
      this.edit.price = 0;
      this.edit.stock = 0;
    },
    async updateProduct() {
      try {
        this.loadingEdit = true;
        const response = await ProductService.update(this.edit);
        this.getProducts();
        this.modal = false;
        Swal.fire({
          position: 'bottom-end',
          toast: true,
          icon: 'success',
          title: response.msg,
          showConfirmButton: false,
          timer: 3000,
        });
        this.updateProductNameInput = '';
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      } finally {
        this.loadingEdit = false;
      }
    },
    async deleteProduct(product_id) {
      try {
        const response = await ProductService.destroy(product_id);
        this.getProducts();
        Swal.fire({
          position: 'bottom-end',
          toast: true,
          icon: 'success',
          title: response.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'ไม่สามารถลบได้!',
          text: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
  },
};
</script>

<style scoped>
.modal-edit-tracking-list {
  width: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: rgba(32, 34, 38, 0.98);
  padding: 1rem 1.2rem;
  border-radius: 3px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  z-index: 999 !important;
}
.modal-title {
  color: #fff;
  font-size: 22px;
}
@media (max-width: 550px) {
  .modal-edit-tracking-list {
    width: 95%;
  }
}
</style>
